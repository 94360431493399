@import url(https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --background-color: rgb(2, 2, 71);
    --border-color: rgb(252, 41, 241);
    --yellow-color: yellow;
}

html,
body {
    font-family: 'Kaushan Script', cursive;
}


/* Accueil Cadeaux ------------------------------------------------------------------ */

.accueilCadeaux {
    width: 100%;
    min-height: 93vh;
    background-image: url(/static/media/cheminee.914956b4.jpg);
    background-size: cover;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7vh;
}

@media screen and (min-width: 1025px) {
    .accueilCadeaux {
        background-image: url(/static/media/sapin.0d1d1bb9.webp);
        background-position: bottom;
    }
}

.cadeau {
    width: 40px;
    position: fixed;
}

.cadeau:hover {
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .cadeau {
        width: 80px;
    }
}

.cadeau1 {
    bottom: 8%;
    left: 5%;
}

.cadeau2 {
    bottom: 15%;
    left: 8%;
}

@media screen and (min-width: 1025px) {
    .cadeau2 {
        bottom: 20%;
    }
}

.cadeau3 {
    bottom: 15%;
    left: 25%;
}

@media screen and (min-width: 1025px) {
    .cadeau3 {
        bottom: 20%;
    }
}

.cadeau4 {
    bottom: 8%;
    left: 25%;
}

.cadeau5 {
    bottom: 8%;
    left: 40%;
}

.cadeau6 {
    bottom: 15%;
    left: 40%;
}

@media screen and (min-width: 1025px) {
    .cadeau6 {
        bottom: 20%;
        left: 32%;
    }
}

.cadeau7 {
    bottom: 15%;
    left: 55%;
}

@media screen and (min-width: 1025px) {
    .cadeau7 {
        bottom: 6%;
        left: 63%;
    }
}

.cadeau8 {
    bottom: 8%;
    left: 55%;
}

.cadeau9 {
    bottom: 8%;
    left: 70%;
}

.cadeau10 {
    bottom: 15%;
    left: 70%;
}

@media screen and (min-width: 1025px) {
    .cadeau10 {
        left: 77%;
    }
}

.cadeau11 {
    bottom: 8%;
    left: 85%;
}

.cadeau12 {
    bottom: 15%;
    left: 85%;
}

@media screen and (min-width: 1025px) {
    .cadeau12 {
        bottom: 20%;
    }
}


/* Accueil Souvenirs ------------------------------------------------------------------ */

.accueilSouvenirs {
    width: 100%;
    min-height: 93vh;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7vh;
}

.star_blue4 {
    position: absolute;
    top: 60%;
    left: 80%;
    width: 100px;
    transform: scale(1);
    -webkit-animation: pulse 4s infinite;
            animation: pulse 4s infinite;
}

.titreAccueil {
    margin-bottom: 5%;
}

.h5TitreAccueil {
    padding-top: 50px;
    margin: 0;
    font-size: 2em;
}

.rubrique {
    display: flex;
    width: 50vw;
    height: 10vh;
    border: solid 2px yellow;
    border: solid 2px var(--yellow-color);
    border-radius: 15px;
    box-shadow: 0 0 25px yellow;
    box-shadow: 0 0 25px var(--yellow-color);
    color: yellow;
    color: var(--yellow-color);
    margin-bottom: 15%;
    text-decoration: none;
}

.rubrique:hover {
    background-color: yellow;
    background-color: var(--yellow-color);
    color: black;
}

.titreRubrique {
    margin: auto;
    font-size: 1.5em;
}


/* Année ------------------------------------------------------------------ */

.annee {
    width: 100%;
    min-height: 93vh;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10vh;
}

.divPhotoPage {
    margin: auto;
    margin-bottom: 5%;
}

.photoPageHorizontale {
    width: 80vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageHorizontale {
        width: 40vw;
    }
}

.photoPageVerticale {
    width: 40vw;
    border-radius: 5px;
    box-shadow: 10px 5px 5px rgb(109, 109, 109);
}

@media screen and (min-width: 1025px) {
    .photoPageVerticale {
        width: 18vw;
    }
}

.titreAnnee {
    padding: 20vh 2% 0 2%;
}

@media screen and (min-width: 1025px) {
    .titreAnnee {
        padding-top: 10vh;
    }
}

.pleurs {
    width: 60vw;
    padding-bottom: 10vh;
}


/* App ------------------------------------------------------------------ */

.app {
    height: 93vh;
    width: 100vw;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    background-color: rgb(2, 2, 71);
}

.star_blue {
    position: absolute;
    top: 0;
    left: 40%;
    width: 100px;
    transform: scale(1);
    -webkit-animation: pulse 2s infinite;
            animation: pulse 2s infinite;
}

.star_blue2 {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100px;
    transform: scale(1);
    -webkit-animation: pulse 3s infinite;
            animation: pulse 3s infinite;
}

.star_blue3 {
    position: absolute;
    top: 60%;
    left: 60%;
    width: 100px;
    transform: scale(1);
    -webkit-animation: pulse 4s infinite;
            animation: pulse 4s infinite;
}

@-webkit-keyframes pulse {
    0% {
        transform: scale(0.90);
    }
    12% {
        transform: scale(0.85);
    }
    25% {
        transform: scale(0.80);
    }
    37% {
        transform: scale(0.75);
    }
    50% {
        transform: scale(0.70);
    }
    62% {
        transform: scale(0.75);
    }
    75% {
        transform: scale(0.80);
    }
    87% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(0.90);
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.90);
    }
    12% {
        transform: scale(0.85);
    }
    25% {
        transform: scale(0.80);
    }
    37% {
        transform: scale(0.75);
    }
    50% {
        transform: scale(0.70);
    }
    62% {
        transform: scale(0.75);
    }
    75% {
        transform: scale(0.80);
    }
    87% {
        transform: scale(0.85);
    }
    100% {
        transform: scale(0.90);
    }
}

.liens {
    margin: auto;
}

.lien {
    width: 50vw;
    height: 10vh;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    display: flex;
    margin-bottom: 25%;
    border: solid 2px yellow;
    border: solid 2px var(--yellow-color);
    border-radius: 15px;
    box-shadow: 0 0 25px yellow;
    box-shadow: 0 0 25px var(--yellow-color);
    text-decoration: none;
    color: yellow;
    color: var(--yellow-color);
}

@media screen and (min-width: 1025px) {
    .lien {
        margin-bottom: 10%;
    }
}

.lien:hover {
    background-color: yellow;
    background-color: var(--yellow-color);
    color: black;
}

.titleLien {
    margin: auto;
    text-decoration: none;
    font-size: 1.5em;
}


/* Cadeau ------------------------------------------------------------------ */

.accueilCadeau {
    width: 100%;
    min-height: 93vh;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    background-size: cover;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 7vh;
}


/* Cadeau1 ------------------------------------------------------------------ */

h1 {
    padding-top: 15vh;
}

.excitation {
    width: 60vw;
}

.fleche {
    height: 50px;
}

.orange {
    width: 20vw;
}

.deception {
    width: 60vw;
    padding: 5%;
}


/* Cadeau2 ------------------------------------------------------------------ */

.cheval {
    width: 60vw;
    padding-top: 20vh;
}

@media screen and (min-width: 1025px) {
    .cheval {
        padding-top: 10vh;
    }
}

.swing {
    transform-origin: top center;
    -webkit-animation: swing 4s ease infinite;
            animation: swing 4s ease infinite;
}

@-webkit-keyframes swing {
    0% {
        transform: rotate(5deg);
    }
    16.5% {
        transform: rotate(-5deg);
    }
    33% {
        transform: rotate(5deg);
    }
    49.5% {
        transform: rotate(-5deg);
    }
    65% {
        transform: rotate(5deg);
    }
    82% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}

@keyframes swing {
    0% {
        transform: rotate(5deg);
    }
    16.5% {
        transform: rotate(-5deg);
    }
    33% {
        transform: rotate(5deg);
    }
    49.5% {
        transform: rotate(-5deg);
    }
    65% {
        transform: rotate(5deg);
    }
    82% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}


/* Cadeau3 ------------------------------------------------------------------ */

.accueilCadeau3 {
    background-image: url(/static/media/caverne.436c9072.jpg);
    background-position: 75% 75%;
}

.tresor {
    position: fixed;
    bottom: 15%;
    left: 15%;
    width: 50%;
}

@media screen and (min-width: 768px) {
    .tresor {
        width: 20%;
        left: 45%;
    }
}

.pieces {
    position: fixed;
    bottom: 10%;
    left: 55%;
    width: 10%;
}

@media screen and (min-width: 768px) {
    .pieces {
        width: 5%;
        left: 65%;
    }
}

.sac_dollars {
    position: fixed;
    bottom: 22%;
    left: 55%;
    width: 15%;
}

@media screen and (min-width: 768px) {
    .sac_dollars {
        width: 5%;
        left: 65%;
    }
}

.sac_dollars2 {
    bottom: 25%;
    left: 60%;
}

@media screen and (min-width: 768px) {
    .sac_dollars2 {
        left: 70%;
    }
}

.lingots {
    position: fixed;
    bottom: 8%;
    left: 35%;
    width: 15%;
}

@media screen and (min-width: 768px) {
    .lingots {
        width: 5%;
        left: 50%;
        bottom: 9%;
    }
}

.billets {
    position: fixed;
    bottom: 7%;
    right: 10%;
    width: 15%;
}

@media screen and (min-width: 768px) {
    .billets {
        width: 5%;
        right: 5%;
        bottom: 8%;
    }
}

.tirelire {
    position: fixed;
    bottom: 7%;
    left: 10%;
    width: 15%;
}

@media screen and (min-width: 768px) {
    .tirelire {
        width: 5%;
        left: 35%;
        bottom: 8%;
    }
}

.dollars {
    width: 55%;
    position: fixed;
    top: 0;
    left: 30%;
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 5s;
            animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes example {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@keyframes example {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@media screen and (min-width: 1025px) {
    .dollars {
        width: 25%;
        left: 40%;
    }
}


/* Cadeau4 ------------------------------------------------------------------ */

.accueilCadeau4 {
    background-image: url(/static/media/paysage.38be6ea8.jpg);
    background-position: 75% 75%;
}

.fontaine {
    position: fixed;
    bottom: 10%;
    left: 5%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .fontaine {
        width: 10%;
    }
}

.fontaine:hover {
    transform: scale(1.1);
}

.gateau1 {
    position: fixed;
    bottom: 10%;
    right: 5%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .gateau1 {
        width: 10%;
        right: 8%;
    }
}

.gateau1:hover {
    transform: scale(1.1);
}

.bonbons1 {
    position: fixed;
    bottom: 8%;
    left: 45%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .bonbons1 {
        width: 10%;
    }
}

.bonbons1:hover {
    transform: scale(1.1);
}

.gateau2 {
    position: fixed;
    bottom: 20%;
    left: 35%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .gateau2 {
        width: 10%;
    }
}

.gateau2:hover {
    transform: scale(1.1);
}

.bonbons2 {
    position: fixed;
    bottom: 20%;
    right: 20%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .bonbons2 {
        width: 10%;
    }
}

.bonbons2:hover {
    transform: scale(1.1);
}

.sucette {
    position: fixed;
    bottom: 30%;
    left: 35%;
    width: 20%;
    transition: transform 500ms;
}

@media screen and (min-width: 768px) {
    .sucette {
        width: 7%;
        left: 50%;
    }
}

.sucette:hover {
    transform: rotate(360deg);
}

.canne2 {
    position: fixed;
    bottom: 30%;
    right: 15%;
    width: 20%;
    transition: transform 500ms;
}

@media screen and (min-width: 768px) {
    .canne2 {
        width: 7%;
        right: 20%;
    }
}

.canne2:hover {
    transform: rotate(360deg);
}

.canne1 {
    position: fixed;
    bottom: 30%;
    left: 5%;
    width: 20%;
    transition: transform 500ms;
}

@media screen and (min-width: 768px) {
    .canne1 {
        width: 7%;
        left: 20%;
    }
}

.canne1:hover {
    transform: rotate(360deg);
}


/* Cadeau5 ------------------------------------------------------------------ */

.titreAmour {
    font-size: 1.4em;
}

@media screen and (min-width: 768px) {
    .titreAmour {
        font-size: 2em;
    }
}

.coeur {
    width: 15%;
    position: fixed;
    top: 0;
    left: 15%;
    -webkit-animation-name: example;
            animation-name: example;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@keyframes example {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@media screen and (min-width: 1025px) {
    .coeur {
        width: 5%;
    }
}

.coeur2 {
    width: 15%;
    position: fixed;
    top: 0;
    right: 10%;
    -webkit-animation-name: example2;
            animation-name: example2;
    -webkit-animation-duration: 6s;
            animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes example2 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@keyframes example2 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@media screen and (min-width: 1025px) {
    .coeur2 {
        width: 5%;
    }
}

.coeur3 {
    width: 15%;
    position: fixed;
    top: 0;
    left: 40%;
    -webkit-animation-name: example3;
            animation-name: example3;
    -webkit-animation-duration: 8s;
            animation-duration: 8s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes example3 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@keyframes example3 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@media screen and (min-width: 1025px) {
    .coeur3 {
        width: 5%;
    }
}

.coeur4 {
    width: 15%;
    position: fixed;
    top: 0;
    right: 20%;
    -webkit-animation-name: example4;
            animation-name: example4;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes example4 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@keyframes example4 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@media screen and (min-width: 1025px) {
    .coeur4 {
        width: 5%;
    }
}

.coeurs1 {
    position: fixed;
    top: 30%;
    left: 25%;
    width: 50%;
}

@media screen and (min-width: 1025px) {
    .coeurs1 {
        width: 25%;
        left: 38%;
    }
}

.coeurs2 {
    width: 35%;
    position: fixed;
    top: 0;
    left: 15%;
    -webkit-animation-name: example5;
            animation-name: example5;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes example5 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@keyframes example5 {
    from {
        top: 0;
    }
    to {
        top: 95%;
    }
}

@media screen and (min-width: 1025px) {
    .coeurs2 {
        width: 25%;
    }
}


/* Cadeau6 ------------------------------------------------------------------ */

.pull {
    width: 60vw;
    padding-top: 20vh;
}

@media screen and (min-width: 1025px) {
    .pull {
        width: 30vw;
        padding-top: 10vh;
    }
}


/* Cadeau7 ------------------------------------------------------------------ */

.desole {
    padding-top: 20vh;
}

@media screen and (min-width: 1025px) {
    .desole {
        padding-top: 10vh;
    }
}

.notfound {
    width: 60vw;
}

@media screen and (min-width: 1025px) {
    .notfound {
        width: 30vw;
    }
}


/* Cadeau8 ------------------------------------------------------------------ */

.titreVoyage {
    padding-top: 20vh;
    padding-bottom: 5vh;
}

@media screen and (min-width: 1025px) {
    .titreVoyage {
        padding-top: 10vh;
    }
}

.voyage {
    width: 60vw;
}

@media screen and (min-width: 1025px) {
    .voyage {
        width: 30vw;
    }
}

.hithere {
    -webkit-animation: hithere 1s ease infinite;
            animation: hithere 1s ease infinite;
}

@-webkit-keyframes hithere {
    30% {
        transform: scale(1.2);
    }
    40%,
    60% {
        transform: rotate(-5deg) scale(1.2);
    }
    50% {
        transform: rotate(5deg) scale(1.2);
    }
    70% {
        transform: rotate(0deg) scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes hithere {
    30% {
        transform: scale(1.2);
    }
    40%,
    60% {
        transform: rotate(-5deg) scale(1.2);
    }
    50% {
        transform: rotate(5deg) scale(1.2);
    }
    70% {
        transform: rotate(0deg) scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


/* Cadeau9 ------------------------------------------------------------------ */

.accueilCadeau9 {
    background-image: url(/static/media/bibliotheque.e415722b.jpg);
    background-position: 45% 75%;
}

.citation {
    margin-top: 20vh;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    color: white;
}

@media screen and (min-width: 768px) {
    .citation {
        font-size: 2em;
    }
}

.livres1 {
    position: fixed;
    bottom: 10%;
    left: 5%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .livres1 {
        width: 10%;
        left: 30%;
    }
}

@media screen and (min-width: 1025px) {
    .livres1 {
        width: 5%;
    }
}

.livres2 {
    position: fixed;
    bottom: 8%;
    left: 25%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .livres2 {
        width: 10%;
        left: 40%;
    }
}

@media screen and (min-width: 1025px) {
    .livres2 {
        width: 5%;
    }
}

.livres3 {
    position: fixed;
    bottom: 20%;
    left: 40%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .livres3 {
        width: 10%;
        left: 48%;
    }
}

@media screen and (min-width: 1025px) {
    .livres3 {
        width: 5%;
    }
}

.livres4 {
    position: fixed;
    bottom: 15%;
    left: 55%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .livres4 {
        width: 10%;
        left: 55%;
    }
}

@media screen and (min-width: 1025px) {
    .livres4 {
        width: 5%;
    }
}

.livres5 {
    position: fixed;
    bottom: 7%;
    right: 5%;
    width: 20%;
}

@media screen and (min-width: 768px) {
    .livres5 {
        width: 10%;
        right: 38%;
    }
}

@media screen and (min-width: 1025px) {
    .livres5 {
        width: 5%;
    }
}


/* Cadeau10 ------------------------------------------------------------------ */

.titreSac {
    padding-top: 20vh;
}

@media screen and (min-width: 1025px) {
    .titreSac {
        padding-top: 10vh;
    }
}

.sac_mary_poppins {
    width: 60vw;
}

@media screen and (min-width: 1025px) {
    .sac_mary_poppins {
        width: 30vw;
    }
}

.mary_poppins {
    width: 60vw;
}

@media screen and (min-width: 1025px) {
    .mary_poppins {
        width: 30vw;
    }
}


/* Cadeau11 ------------------------------------------------------------------ */

.titreLampe {
    padding-top: 20vh;
}

@media screen and (min-width: 1025px) {
    .titreLampe {
        padding-top: 10vh;
    }
}

.genie {
    width: 30vw;
    padding-right: 50%;
}

@media screen and (min-width: 1025px) {
    .genie {
        width: 15vw;
    }
}

.lampe {
    width: 30vw;
}

@media screen and (min-width: 1025px) {
    .lampe {
        width: 15vw;
    }
}


/* Cadeau12 ------------------------------------------------------------------ */

.accueilCadeau12 {
    background-image: url(/static/media/pere_noel.6a2b3d13.jpg);
    background-position: 50% 90%;
}

@media screen and (min-width: 1025px) {
    .accueilCadeau12 {
        background-position: top 30% right 100px;
    }
}

.rdv {
    color: black;
    position: fixed;
    bottom: 30%;
}

@media screen and (min-width: 768px) {
    .rdv {
        font-size: 2em;
    }
}

@media screen and (min-width: 1025px) {
    .rdv {
        bottom: 15%;
    }
}


/* Footer ------------------------------------------------------------------------- */

.bodyFooter {
    height: 7vh;
    width: 100vw;
    display: flex;
    position: fixed;
    bottom: 0;
    z-index: 5;
    background-color: blue;
}

.divFooter {
    width: calc(100% / 2);
    height: 7vh;
    display: flex;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    border-top: solid 2px rgb(252, 41, 241);
    border-top: solid 2px var(--border-color);
    text-decoration: none;
}

.divFooter:hover {
    text-decoration: none;
    background-color: rgb(252, 41, 241);
    background-color: var(--border-color);
    color: white;
}

.divFooter1 {
    border-right: solid 1px rgb(252, 41, 241);
    border-right: solid 1px var(--border-color);
}

.divFooter2 {
    border-left: solid 1px rgb(252, 41, 241);
    border-left: solid 1px var(--border-color);
}

.ecStyle {
    background-color: rgb(252, 41, 241);
    background-color: var(--border-color);
    text-decoration: none;
}

.linkFooter {
    margin: auto;
    color: white;
    height: 100%;
}

.colorLinkFooter {
    font-size: 0.7em;
    height: 100%;
    display: flex;
}

@media screen and (min-width: 760px) {
    .colorLinkFooter {
        font-size: 1.4em;
    }
}


/* Home ------------------------------------------------------------------------- */

.home {
    position: fixed;
    z-index: 2;
    width: 80px;
    height: 50px;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    display: flex;
    border: solid 2px rgb(252, 41, 241);
    border: solid 2px var(--border-color);
    border-radius: 5px;
}

.home:hover {
    cursor: pointer;
    background-color: rgb(252, 41, 241);
    background-color: var(--border-color);
    color: white;
    text-decoration: none;
}

.divHome {
    margin: auto;
    color: white;
}


/* not found ------------------------------------------------------------------------- */

.notFound {
    height: 93vh;
    width: 100vw;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    background-color: rgb(2, 2, 71);
}

.divNotFound {
    margin: auto;
    color: white;
}


/* Retour Cadeaux ------------------------------------------------------------------------- */

.retourCadeaux {
    position: fixed;
    z-index: 2;
    top: 51px;
    width: 80px;
    height: 50px;
    background-color: rgb(2, 2, 71);
    background-color: var(--background-color);
    display: flex;
    border: solid 2px rgb(252, 41, 241);
    border: solid 2px var(--border-color);
    border-radius: 5px;
}

.retourCadeaux:hover {
    cursor: pointer;
    background-color: rgb(252, 41, 241);
    background-color: var(--border-color);
    color: white;
    text-decoration: none;
}

.divRetourCadeaux {
    margin: auto;
    color: white;
}
